/* LoginPage.tsx */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-page__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.login-page__box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.login-page__box-text {
  font-size: 14px;
  text-align: center;
}

.login-page__box-text-link {
  margin-left: 4px;
  color: blue;
  text-decoration: none;
}
