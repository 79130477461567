/* GoogleUser.tsx */
.google-user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.google-user__avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.google-user__email {
  font-weight: 400;
  font-size: 14px;
  color: #232323;
}
